<template lang="pug">
div
  h2 Charts
  figure.highlighted
    .d-flex.mb-3
      dashboard-stat-chart.w-100(:totalStatistics="totalStatistics")
</template>

<script>
  import moment from 'moment';
  import DashboardStatChart from '@/components/Charts/DashboardStat.vue';
  import GET_TOTAL_STATISTICS from '@/graphql/GetTotalStatistics.gql';

  export default {
    components: { DashboardStatChart },

    data() {
      return {
        totalStatistics: {
          stats: [],
          labels: [],
        },
      };
    },

    mounted() {
      // eslint-disable-next-line
      Prism.highlightAll();
      this.$nextTick(() => {
        if (this.isCodeInserted && this.noStats) {
          this.$apollo.queries.totalStatistics.refetch();
        }
      });
    },

    apollo: {
      totalStatistics: {
        query: GET_TOTAL_STATISTICS,
        variables() {
          return {
            interval: {
              from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
              to: moment().format('YYYY-MM-DD'),
            },
          };
        },
      },
    },
  };
</script>
